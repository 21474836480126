<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>产品管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row :gutter="20">
          <el-col :span="8" v-for="(card,index) in dataList" :key="card.id">
            <div class="product-card" :class="'product-card'+(index+1)">
              <div class="product-card-title">{{ card.title }}</div>
              <div class="product-card-flex">
                <div class="product-card-item" v-for="item in card.menuList" :key="item.id" @click="handleToDetail(item)">
                  <div class="product-card-icon">
                    <i class="icon iconfont" :class="item.icon"></i>
                  </div>
                  <div>{{ item.title }}</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-card style="margin-top: 15px">
          <el-row class="title_row">
            <el-col>
              <span class="title_class">订单概览</span>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="4" v-for="(item, i) of processedList" v-bind:key="i">
              <gameTransactionBackview :itemData="item" :key="i" @itemClicked="handleToolToDetail"></gameTransactionBackview>
              <!-- <div class="processed-list-con" @click="handleToDetail(item)">
                <div class="processed-image" :class="'coloricon' + (i + 1)">
                  <i class="icon iconfont" :class="item.icon"></i>
                </div>
                <div class="processed-count">
                  <div class="processed-desc">{{ item.number }}</div>
                  <div class="processed-title">{{ item.title }}</div>
                </div>
              </div> -->
            </el-col>
          </el-row>
        </el-card>
        <el-row :gutter="16">
          <el-col :span="12">
            <el-card style="margin-top: 15px">
              <el-row class="title_row">
                <el-col>
                  <span class="title_class">商品总览</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col
                  :span="6"
                  v-for="(item, i) of goodsList"
                  v-bind:key="i"
                >
                  <div class="processed-list-back">
                    <div class="dataStatistics-desc">{{ item.number }}</div>
                    <div class="dataStatistics-title">{{ item.title }}</div>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card style="margin-top: 15px">
              <el-row class="title_row">
                <el-col>
                  <span class="title_class">用户总览</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col
                  :span="6"
                  v-for="(item, i) of userList"
                  v-bind:key="i"
                >
                  <div class="processed-list-back">
                    <div class="dataStatistics-desc">{{ item.number }}</div>
                    <div class="dataStatistics-title">{{ item.title }}</div>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
        <el-card style="margin-top: 15px">
          <el-row class="title_row">
            <el-col>
              <span class="title_class">营销工具</span>
            </el-col>
          </el-row>
          <div class="tool-con">
            <div class="tool-item" v-for="(item,index) in toolList" :key="item.id">
              <div class="tool-item-icon" :class="'coloricon-reverse'+(index+1)"><i class="icon iconfont" :class="item.icon"></i></div>
              <div class="tool-item-title">{{ item.title }}</div>
            </div>
          </div>
        </el-card>
    </div>
</template>
<script>
import { hasPermission } from '@/permission/index.js'
import { indexStatistics } from '@/http/api'
export default {
  data () {
    return {
      dataList: [
        {
          id: 1,
          title: '订单管理',
          menuList: [
            {
              id: 1,
              title: '产品订单管理',
              icon: 'icon-dingdandingdanmingxishouzhimingxi',
              path: '/product_Management/product_management',
              flag: 'product_management'
            },
            {
              id: 2,
              title: '订单开通管理',
              icon: 'icon-gouwucheman',
              path: '/product_Management/launch_product_management',
              flag: 'launch_product_management'
            },
            {
              id: 3,
              title: '蜂计划管理',
              icon: 'icon-huihuajinglingicon-351',
              path: '/product_Management/product_planbee_management',
              flag: 'product_planbee_management'
            }
          ]
        },
        {
          id: 2,
          title: '产品管理',
          menuList: [
            {
              flag: this.$chnEngStatusCode.productcoupon_management,
              id: 3,
              title: '优惠券管理',
              icon: 'icon-youhuiquan',
              path: '/product_Management/productcoupon_management'
            },
            // {
            //   id: 1,
            //   title: '实物商品',
            //   icon: 'icon-gouwucheman',
            //   path: ''
            // },
            // {
            //   id: 2,
            //   title: '虚拟商品',
            //   icon: 'icon-chanpin',
            //   path: ''
            // },
            {
              flag: this.$chnEngStatusCode.productcode_management,
              id: 3,
              title: '兑换码管理',
              icon: 'icon-chanpinbianma',
              path: '/product_Management/productcode_management'
            }
          ]
        },
        {
          id: 3,
          title: '课程管理',
          menuList: [
            {
              id: 1,
              title: '学校课程管理',
              icon: 'icon-house-full',
              path: '/product_Management/course_schoolorder_management'
            },
            {
              id: 2,
              title: '教师课程管理',
              icon: 'icon-jiaoshi',
              path: '/product_Management/course_teacherorder_management'
            }
          ]
        }
      ],
      toolList: [
        {
          id: 1,
          title: '优惠套餐',
          icon: 'icon-taocantuijian',
          path: ''
        },
        {
          id: 2,
          title: '满减',
          icon: 'icon-cuxiaoguanli',
          path: ''
        },
        {
          id: 3,
          title: '新客优惠券',
          icon: 'icon-quan',
          path: ''
        },
        {
          id: 4,
          title: '优惠券',
          icon: 'icon-youhuiquan',
          path: ''
        },
        {
          id: 5,
          title: 'N元任选',
          icon: 'icon-qiehuanmoshi',
          path: ''
        },
        {
          id: 6,
          title: '拼团购',
          icon: 'icon-tuangou',
          path: ''
        },
        {
          id: 7,
          title: '限时折扣',
          icon: 'icon-zhekou',
          path: ''
        },
        {
          id: 8,
          title: '秒杀管理',
          icon: 'icon-miaosha',
          path: ''
        },
        {
          id: 9,
          title: '新品推荐',
          icon: 'icon-xinpin1',
          path: ''
        }
      ],
      processedList: [
        {
          icon: 'icon-daifahuo',
          title: '待缴费订单',
          key: 'prepaidOrder',
          path: '/product_Management/product_management',
          status: '1',
          flag: 'product_management1',
          menuTag: '1',
          number: 0
        },
        // {
        //   icon: 'icon-fahuo2',
        //   title: '待发货订单',
        //   key: 'orderForDelivery',
        //   path: '/product_Management/product_management',
        //   status: '2',
        //   flag: 'product_management2',
        //   menuTag: '2',
        //   number: 0
        // },

        // {
        //   icon: 'icon-truck-completed_fill',
        //   title: '已发货订单',
        //   key: 'shippedOrder',
        //   path: '/product_Management/product_management',
        //   status: '3',
        //   flag: 'product_management3',
        //   menuTag: '3',
        //   number: 0
        // },
        // {
        //   icon: 'icon-gouwucheman',
        //   title: '待确认收货订单',
        //   key: 'returnOrderToBeConfirmed',
        //   path: '/product_Management/product_management',
        //   status: '11',
        //   flag: 'product_management11',
        //   menuTag: '6',
        //   number: 0
        // },
        {
          icon: 'icon-yiwancheng',
          title: '已完成订单',
          key: 'completedOrder',
          path: '/product_Management/product_management',
          status: '4',
          menuTag: '4',
          flag: 'product_management4',
          number: 0
        },
        {
          icon: 'icon-tuihuo',
          title: '待处理退款订单',
          key: 'pendingReturnOrder',
          path: '/product_Management/product_return_management',
          flag: 'product_return_management',
          menuTag: '5',
          number: 0
        },
        {
          icon: 'icon-cuoqingdengji',
          title: '新缺货登记',
          key: 'newOutOfStockRegistration',
          menuTag: '7',
          number: 0
        },
        {
          icon: 'icon-guanggaoguanli',
          title: '广告位即将到期',
          key: 'theAdSpaceIsAboutToExpire',
          menuTag: '8',
          number: 0
        }
      ],
      goodsList: [
        {
          title: '虚拟商品',
          number: 0
        },
        {
          title: '实物商品',
          number: 0
        },
        {
          title: '已下架',
          number: 0
        },
        {
          title: '全部商品',
          number: 0
        }
      ],
      userList: [
        {
          title: '今日新增',
          number: 0
        },
        {
          title: '昨日新增',
          number: 0
        },
        {
          title: '本月新增',
          number: 0
        },
        {
          title: '会员总数',
          number: 0
        }
      ]

    }
  },
  created () {
    this.getData()
    this.getProductTypeNum()
  },
  methods: {
    // 获取订单数
    getProductTypeNum () {
      indexStatistics({}).then((res) => {
        console.log('indexStatistics________', res)
        this.processedList.forEach(item => {
          if (item.flag === 'product_management1') {
            item.number = res.data.payNum
          } else if (item.flag === 'product_management4') {
            item.number = res.data.finishNum
          } else if (item.flag === 'product_return_management') {
            item.number = res.data.refundNum
          }
        })
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handleToDetail (itemData) {
      if (this.hasPerms(itemData.flag)) {
        if (itemData.path) {
          this.$router.push(itemData.path)
        }
      } else {
        this.$message.warning('暂无操作权限')
      }
    },
    hasPerms: function (perms) {
      // 根据权限标识和外部指示状态进行权限判断
      return hasPermission(perms) // & !this.disabled
    },
    handleToolToDetail ({ path, status }) {
      if (path) {
        const query = status ? { status } : null
        this.$router.push({ path, query })
      }
    },
    getData () {
      // historicalProductStatistics({ activityType: this.$chnEngStatusCode.activityType }).then(res => {
      //   Object.keys(res.data).forEach(key => {
      //     this.processedList.find(item => item.key === key).number = res.data[key]
      //   })
      // })
    }
  }
}
</script>
<style scoped lang="less">
.product-card{
  height: 120px;
  color: #fff;
  border-radius: 6px;
  .product-card-title{
    height: 36px;
    line-height: 36px;
    padding-left: 20px;
    background-color: rgba(255,255,255,.15);
  }
  .product-card-flex{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .product-card-item{
    flex: 1;
    text-align: center;
    height: 54px;
    margin-top: 15px;
    border-right: 1px solid rgba(255,255,255,.2);
    font-size: 14px;
    cursor: pointer;
    &:last-child{
      border-right: none;
    }
    .iconfont{
      font-size: 24px;
    }
  }
  .product-card-icon{
    margin-bottom: 4px;
  }
}
.product-card1{background-color: #38b7de;}
.product-card2{background-color: #fa7d69;}
.product-card3{background-color: #1CBB9C;}

.tool-con{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tool-item{
    text-align: center;
    flex: 1;
  }
  .tool-item-icon{
    width: 44px;
    height: 44px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 8px;
    .iconfont{
      font-size: 24px;
    }
  }
  .tool-item-title{
    color: #333;
    font-size: 14px;
  }
}

</style>
